import React from "react"

export default function Contact() {
  return (
    <section id="contact" className="spacey">
      <h2 className="upper centre">Contact</h2>

      <ul>
        <li>
          Booking:{" "}
          <a href="mailto:thegertrudes@gmail.com">thegertrudes@gmail.com</a>
        </li>
        <li>
          Publicity:{" "}
          <a href="mailto:stephanie@hardcopymedia.ca">
            stephanie@hardcopymedia.ca
          </a>
        </li>
        <li>
          Distribution:{" "}
          <a href="mailto:chris@outside-music.com">chris@outside-music.com</a>
        </li>
        <li>
          Management:{" "}
          <a href="mailto:annemarie@bottletreeinc.com">
            annemarie@bottletreeinc.com
          </a>
        </li>
        <li>
          Wolfe Island Records:{" "}
          <a href="mailto:hchrisbrown@gmail.com">hchrisbrown@gmail.com</a>
        </li>
        <li>
          Zunior: <a href="mailto:dave@zunior.com">dave@zunior.com</a>
        </li>
      </ul>
    </section>
  )
}
