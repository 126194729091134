import React from 'react'
import Layout from '../components/Layout'
import NewAlbum from '../components/NewAlbum'
import WhoAreTheGertrudes from '../components/WhoAreTheGertrudes'
import Video from '../components/Video'
import MediaKit from '../components/MediaKit'
import Quote from '../components/Quote'
import Music from '../components/Music'
import MailingList from '../components/MailingList'
import News from '../components/News'
import Sponsors from '../components/Sponsors'
import Contact from '../components/Contact'

const title = 'The Gertrudes'

export default function Home() {
  return (
    <Layout title={title}>
      <article id="home">
        <section className="container">
          <WhoAreTheGertrudes />
          <NewAlbum />
          <News />
          <MailingList />
          <Video />
          <MediaKit />
          <Quote />
          <Music />
          <Sponsors />
          <Contact />
        </section>
      </article>
    </Layout>
  )
}
