import React from "react"

export default function MailingList() {
  return (
    <section id="mailing-list" className="spacey">
      <h2 className="upper centre">Newsletter</h2>
      <p>Sign up for The Gertrudes newsletter to stay up to date on shows, new releases, and band news!.</p>
      <p className="mailchimp">
        <a className="button yellow_border" href="http://eepurl.com/ingHs2">
          Sign Up
        </a>
      </p>
    </section>
  )
}
