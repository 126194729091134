import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Quote() {
  return (
    <section id="quote" className="centre">
      <StaticImage
        as="figure"
        src="../images/illustrations/Hands_blue.png"
        alt="hands"
        objectFit="contain"
        width="400"
      />
      <blockquote className="border yellow_border">
        The Gertrudes sound like an ol’ time saloon party in deep space.
        Passengers travelling with this down-home folkestra lay down
        experimental noise beds and frolic through a veritable orchestra of
        instruments.
      </blockquote>
    </section>
  )
}
