import React from "react"
// import { StaticImage } from "gatsby-plugin-image"

export default function News() {
  return (
    <section id="news" className="spacey">
      <h2 className="upper centre">News</h2>

      <ol className="stories">
        {/* <li className="story">
          <StaticImage as="figure" src="../images/gigs/MUSICwestport-2024-Weekend.jpg" alt="Gertrudes gig" objectFit="contain" className="border" />
          <article>
            <h3>Sat, Aug.17 @ 5PM</h3>
            <p>Lockwood Park Stage, Westport, Ontario</p>
            <p>By donation</p>
            <p><a class="button" href="https://coveinn.com/event/musicwestport-2023/">More Information</a> </p>
          </article>
        </li> */}

        <li className="story">
          <article>
            <h3>Sat, Sep.14 @ 8PM</h3>
            <p>
              <a href="musiikkicafe.com">Musiikki Cafe</a>{" "}
            </p>
            <p>Pay What You Can</p>
          </article>
        </li>

        <li className="story">
          <article>
            <h3>Sun, Nov.3 @ 3PM</h3>
            <p>
              <a href="https://www.broomfactory.ca/">The Broom Factory</a>{" "}
            </p>
            <p>All Ages</p>
            <p>Pay What You Can</p>
          </article>
        </li>

        <li className="story">
          <article>
            <h3>Sat, Dec.7 @ 4PM</h3>
            <p>
              <a href="https://hotelwolfeisland.com/">Hotel Wolfe Island</a>{" "}
            </p>
            <p>Pay What You Can</p>
          </article>
        </li>
      </ol>
    </section>
  )
}
