import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Sponsors() {
  return (
    <section id="sponsors" className="spacey">
      <h2 className="upper centre">Sponsors</h2>
      <ol className="stories">
        <li className="story">
          <StaticImage
            as="figure"
            src="../images/daft-logo.png"
            alt="Daft berr logo"
            objectFit="contain"
          />
          <article>
            <h3 className="upper">Daft Brewing</h3>
            <p>
              Thanks to everyone at Daft Brewing for sponsoring The Gertrudes.
              Check out <a href="https://daftbrewing.com">daftbrewing.com</a> to
              learn about what they do!
            </p>
          </article>
        </li>
      </ol>
    </section>
  )
}
