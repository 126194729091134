import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function MediaKit() {
  return (
    <section id="media_kit" className="spacey centre">
      <h2 className="upper centre">Media Kit</h2>
      <article>
        <section>
          <StaticImage
            as="figure"
            src="../images/The-Gertrudes-credit-Cam-Nelles.jpg"
            alt="The Gertrudes"
            objectFit="contain"
          />
          <p>The Gertrudes - Photo by Cam Nelles</p>
        </section>

        <section>
          <a href="The-Gertrudes-Bio.pdf" className="button yellow_border">
            Download Bio
          </a>
          <StaticImage
            as="figure"
            src="../images/illustrations/Watch.png"
            alt="hands"
            objectFit="contain"
            width="300"
          />
        </section>
      </article>
    </section>
  )
}
