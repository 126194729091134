import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function WhoAreTheGertrudes() {
  return (
    <section id="who_are_the_gertrudes" className="spacey">
      <article className="space">
        <h2 className="upper">Who are the Gertrudes?</h2>
        <p>
          The Gertrudes bring together a versatile collective of musicians to
          create a unique and experimental folkestra in Kingston, ON. They have
          produced four full length albums, two EPS, participated in numerous
          festivals (notably Pop Montreal, Vancouver Folk Festival, Halifax Pop
          Explosion) and shared the stage with artists such Sarah Harmer,
          Calexico, and Ricky Skaggs. Recordings and performances have brought
          together a manic collaboration of choirs, symphonies and over 100
          local musicians.
        </p>
      </article>
      <StaticImage
        as="figure"
        aspectRatio={6 / 4}
        src="../images/illustrations/Drums.png"
        alt="drums"
        objectFit="contain"
      />
    </section>
  )
}
